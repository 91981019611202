import { Button } from "@atoms/button/button";
import { Page } from "@atoms/layout/page";
import Tabs from "@atoms/tabs";
import { useShopLocations } from "@features/general/auth/state/use-store-location";
import { ChartPieIcon, CheckIcon } from "@heroicons/react/outline";
import { Table } from "@molecules/table";
import { Column } from "@molecules/table/table";
import { useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import { ProductGraphModalAtom } from "./graph-modal";
import { InputCounter } from "@atoms/input/input-counter";
import { useSuppliers } from "@features/supplier/state/use-suppliers";
import { ReassortProductLine } from "@features/supplier/types";
import { Title } from "@atoms/text";
import { getReassortTabColumns } from "./reassort-tabs";
import { Input } from "@atoms/input/input-text";
import toast from "react-hot-toast";
import { useAuth } from "@features/general/auth/state/use-auth";

const ReassortPage = () => {
  const { current } = useShopLocations();
  const { user } = useAuth();
  const [tabIndex, setTabIndex] = useState<number | string>(
    current && current.items ? current!.items[0].codeTypeProd : ""
  );
  const [currentModeGest, setCurrentModeGest] = useState<string>(
    current && current.items ? current!.items[0].modeGest : "P"
  );
  const {
    loading,
    reassortLines,
    setReassortLines: changeReassortLines,
    getReassortLines,
    updateReassortLine,
  } = useSuppliers();

  const setProductGraphModal = useSetRecoilState(ProductGraphModalAtom);

  useEffect(() => {
    setTimeout(() => {
      const nextSelect = document.querySelector("#choix select");
      if (nextSelect) (nextSelect as HTMLSelectElement).focus();
    }, 250);
  }, [tabIndex]);

  const handleLineChange = (
    rowId: string,
    newValue: string | undefined,
    key: string
  ) => {
    changeReassortLines((prevReassortLines) => {
      const index = prevReassortLines.items.findIndex(
        (l: ReassortProductLine) => l.id === rowId
      );
      if (index !== -1) {
        const updatedItem = {
          ...prevReassortLines.items[index],
          [key]: newValue,
        };
        const updatedItems = [
          ...prevReassortLines.items.slice(0, index),
          updatedItem,
          ...prevReassortLines.items.slice(index + 1),
        ];
        return {
          ...prevReassortLines,
          items: updatedItems,
        };
      }
      return prevReassortLines;
    });
  };

  const handleValidLigne = async (p: any) => {
    const res = await updateReassortLine(
      p.id,
      p.eaN13,
      p.qte,
      p.panie,
      p.tooltip.codeEtat,
      user?.id || ""
    );
    if (res) {
      toast.success("Ligne mise à jour avec succès");
      const updatedLines = await getReassortLines(
        currentModeGest,
        tabIndex.toString(),
        {
          pageNumber: 1,
          pageSize: 10,
          orderBy: "date_creat", // À modifier
          orderDir: "DESC",
        }
      );
      if (updatedLines) {
        changeReassortLines(updatedLines);
      }
    } else {
      toast.error("Erreur lors de la mise à jour de la ligne");
    }
    setTimeout(() => {
      const nextSelect = document.querySelector("#choix select");
      if (nextSelect) (nextSelect as HTMLSelectElement).focus();
    }, 250);
    // setTimeout(() => {
    //   const nextSelect = document.querySelector("#choix select");
    //   if (nextSelect) (nextSelect as HTMLSelectElement).focus();
    // }, 250);
  };

  const commonCols = [
    {
      title: "",
      id: "product_infos_btn",
      orderable: true,
      sticky: true,
      render: (p) => (
        <div className="w-full flex gap-2 justify-center">
          <Button
            className="shrink-0"
            size={"sm"}
            onClick={(e) =>
              setProductGraphModal({
                open: true,
                product: p,
                modeGest: currentModeGest,
                codeTypeProd: tabIndex.toString(),
              })
            }
            theme="secondary"
            data-tooltip="Voir infos ventes"
            data-tooltip-id="my-tooltip-inline"
            data-click="Disponible prochainement !"
            icon={({ className }) => <ChartPieIcon className={className} />}
          />
        </div>
      ),
    },
    {
      title: "Quant.",
      id: "qte",
      sticky: true,
      orderable: true,
      render: (p) => (
        <div style={{ maxWidth: 96, minWidth: 65 }}>
          <InputCounter
            size={"sm"}
            value={p.qte}
            min={0}
            onChange={(newValue) => {
              changeReassortLines((previous) => {
                const tempTab = [...previous.items];
                const reasLineIndex = tempTab.findIndex((l) => l.id === p.id);
                if (reasLineIndex !== -1) {
                  const tempProduct = { ...tempTab[reasLineIndex] };
                  tempProduct.qte = newValue;
                  tempTab[reasLineIndex] = tempProduct;
                  return { ...previous, items: tempTab };
                }
                return previous;
              });
            }}
          />
        </div>
      ),
    },
    {
      title: "Décision",
      id: "choix_decis",
      sticky: true,
      orderable: true,
      render: (p) => (
        <div id="choix" className="flex flex-col gap-1 md:min-w-40">
          <div className="flex gap-1">
            {p.qte > 0 && (
              <Input
                size="sm"
                value={p.panie}
                onChange={(e) => {
                  handleLineChange(p.id, e.target.value, "panie");
                }}
                placeholder="ex: panier..."
              />
            )}
            <Button
              theme={p.qte > 0 ? "valid" : "danger"}
              size="sm"
              data-tooltip="Valider choix"
              onClick={async () => {
                handleValidLigne(p);
                // console.log(p.qte);
                // const res = await updateReassortLine(
                //   p.id,
                //   p.eaN13,
                //   p.qte,
                //   p.panie,
                //   p.tooltip.codeEtat,
                //   user?.id || ""
                // );
                // if (res) {
                //   toast.success("Ligne mise à jour avec succès");
                //   const updatedLines = await getReassortLines(
                //     currentModeGest,
                //     tabIndex.toString(),
                //     {
                //       pageNumber: 1,
                //       pageSize: 10,
                //       orderBy: "ean13", // À modifier
                //       orderDir: "DESC",
                //     }
                //   );
                //   if (updatedLines) changeReassortLines(updatedLines);
                // } else {
                //   toast.error("Erreur lors de la mise à jour de la ligne");
                // }
              }}
              icon={({ className }) => <CheckIcon className={className} />}
            >
              {p.qte > 0 ? "" : "Abandon"}
            </Button>
            {/* <Button
                theme="danger"
                size="sm"
                data-tooltip="Annuler choix"
                onClick={() => handleLineChange(p.id, undefined)}
                icon={({ className }) => <XIcon className={className} />}
              /> */}
          </div>
        </div>
      ),
    },
  ] as Column<any>[];

  return (
    <Page loading={loading}>
      <Title>Réassort</Title>
      <div className="flex flex-col grow items-center gap-6">
        <Tabs
          tabs={
            current?.items
              ? current?.items
                  ?.filter((el) => el.modeGest !== "S")
                  .map((el) => {
                    return { value: el.codeTypeProd, label: el.libTypeProd };
                  })
              : []
          }
          value={tabIndex}
          onChange={async (v) => {
            if (current && current.items) {
              setCurrentModeGest(
                current.items.find((el) => el.codeTypeProd === v)?.modeGest ||
                  "P"
              );
            }
            setTabIndex(v);
          }}
        />{" "}
        <div className="w-full">
          <Table
            className="w-full overflow-x-scroll"
            codePres={`reas${currentModeGest}`}
            scrollable={true}
            key={tabIndex}
            data={reassortLines.items}
            total={reassortLines.pagination.totalRows}
            headerPagination={true}
            showPagination={"full"}
            onRequestData={async ({ page, perPage, order, orderBy }) => {
              const result = await getReassortLines(
                currentModeGest,
                tabIndex.toString(),
                {
                  pageNumber: page,
                  pageSize: perPage,
                  orderBy: orderBy === 0 ? "date_creat" : "date_creat", // À modifier
                  orderDir: order,
                }
              );
              if (result) changeReassortLines(result);
            }}
            columns={[...getReassortTabColumns(currentModeGest), ...commonCols]}
          />
        </div>
      </div>
    </Page>
  );
};

export default ReassortPage;
