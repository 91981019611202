import { Button } from "@atoms/button/button";
import { InputCounter } from "@atoms/input/input-counter";
import { Modal, ModalContent } from "@atoms/modal/modal";
import { Info } from "@atoms/text";
import { ProductsApiClient } from "@features/products/api-client/api-client";
import { useReception } from "@features/reception/state/use-reception";
import { ReceptionLine } from "@features/reception/types";
import { focusInput } from "@features/utils/divers";
import { useControlledEffect } from "@features/utils/hooks/use-controlled-effect";
import { useState } from "react";
import { atom, useRecoilState } from "recoil";

export const PrintLabelsModal = atom({
  key: "PrintLabelsModalAtom",
  default: { open: false, item: {} as ReceptionLine | null },
});

export const PrintLabelsReceptionModal = () => {
  const [printLabelsModals, setPrintLabelsModal] =
    useRecoilState(PrintLabelsModal);
  const [nbrEtiq, setNbrEtiq] = useState(1);
  const { loading } = useReception();
  const [printLoading, setPrintLoading] = useState(false);

  const handleClose = () => setPrintLabelsModal({ open: false, item: null });

  useControlledEffect(() => {
    if (printLabelsModals.open) {
      setNbrEtiq(printLabelsModals.item?.qteRecu || 1);
      setTimeout(() => {
        focusInput("#nbr-etiq");
      }, 250);
    }
  }, [printLabelsModals.open]);

  return (
    <Modal
      className="sm:max-w-[450px]"
      open={printLabelsModals.open}
      loading={printLoading}
      onClose={() => handleClose()}
    >
      <ModalContent title={"Impression d'étiquettes"}>
        <div className="flex flex-col gap-2">
          <Info>{`${printLabelsModals.item?.desig}`} </Info>
          <Info>{`${printLabelsModals.item?.eaN13}`} </Info>
          <div className="flex items-center gap-2 my-2">
            <InputCounter
              id={"nbr-etiq"}
              size="md"
              min={1}
              value={nbrEtiq}
              onChange={(value) => {
                if (value < 1) {
                  setNbrEtiq(1);
                  return;
                }
                setNbrEtiq(value);
              }}
            />
            <Button
              disabled={loading}
              shortcut={["enter"]}
              onClick={async () => {
                try {
                  if (printLabelsModals.item) {
                    setPrintLoading(true);
                    await ProductsApiClient.productLabel([
                      {
                        eaN13: printLabelsModals.item.eaN13,
                        nbrEtiq: nbrEtiq,
                      },
                    ]);
                    setPrintLoading(false);
                  }
                  // await printLabels(
                  //   undefined,
                  //   printLabelsModals.item as ReceptionLine,
                  //   nbrEtiq,
                  //   printLabelsModals.item.linePk
                  // );
                  handleClose();
                } catch {
                  setPrintLoading(false);
                }
              }}
            >
              Imprimer
            </Button>
          </div>
        </div>
      </ModalContent>
    </Modal>
  );
};
